<template lang="pug">
  .jwl-impersonate
    v-select(
      v-model="user"
      placeholder="Impersonate"
      label="label"
      track-by="id"
      :show-labels="false"
      :multiple="false"
      :searchable="true"
      :loading="isLoading"
      :internal-search="false"
      @search-change="fetchUsers"
      @close="options = []"
      @select="impersonate"
      :options="options")

      template(slot="singleLabel", slot-scope="props")
        span.jwl-impersonate__desc
          span.jwl-impersonate__title {{ props.option.label }}

      template(slot="noResult" slot-scope="props")
        | {{props.search.length <= 3 ? '4 letters required' : 'No results found'}}

      template(slot="option", slot-scope="props")
        .jwl-impersonate__desc
          span.jwl-impersonate__title {{ props.option.label }}
          span.jwl-impersonate__small ({{ props.option.jwlId }})

    .jwl-impersonate__exit-button(v-if="currentUser.impersonated" @click="exitImpersonate")
      icon-portal-exit

</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import { IMPERSONATE_KEY } from '../store/Api';

const IconPortalExit = () => import('../assets/fa-icons/duotone/portal-exit.svg');

export default {
  name: 'jwl-impersonate',
  components: {
    IconPortalExit,
    'v-select': Multiselect,
  },
  data () {
    return {
      user: null,
      options: [],
      isLoading: false,
      error: null,
    };
  },
  methods: {
    fetchUsers (search) {
      if (search.length > 3) {
        this.isLoading = true;
        this.$store.dispatch('getImpersonators', search)
          .then((resultList) => {
            this.options = resultList;
            this.isLoading = false;
          })
          .catch((e) => {
            this.error = e;
          });
      }
    },
    impersonate (selectedOption) {
      localStorage.setItem(IMPERSONATE_KEY, selectedOption.id);
      document.location.reload();
    },
    exitImpersonate () {
      localStorage.removeItem(IMPERSONATE_KEY);
      document.location.reload();
    },
  },
  computed: {
    ...mapGetters([
      'currentUser',
    ]),
  },
};
</script>

<style lang="sass">
@import 'src/styles/variables'
@import '../styles/mixins'
@import '../styles/multiselect'

.jwl-impersonate
  align-items: center
  background-color: $bg
  display: flex
  justify-content: flex-start

  @include ltr
    margin-right: 1rem

  @include rtl
    margin-left: 1rem

  &__small
    color: $grey
    font-size: .8em

    @include ltr
      margin-left: .25rem

    @include rtl
      margin-right: .25rem

  .multiselect
    box-sizing: border-box
    min-height: 1.5rem

    &__tags
      border-radius: $border-radius
      padding-bottom: .2rem
      padding-top: .2rem

      input
        padding: 0 0

  &__exit-button
    align-items: center
    background-color: $error
    box-sizing: border-box
    border: .1rem solid $error
    border-radius: $border-radius
    color: $white
    cursor: pointer
    display: flex
    height: 100%
    justify-content: center
    padding: .4rem .4rem
    transition: background-color .25s, color .25s

    @include ltr
      margin-left: .5rem

    @include rtl
      margin-right: .5rem

    &:hover
      background-color: $white
      color: $error

</style>
